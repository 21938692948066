import { createBrowserRouter } from "react-router-dom";
import ExtendTenorSurvey from "../views/ExtendTenorSurvey";
import ReduceMinPaySurvey from "../views/ReduceMinPaySurvey";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <ExtendTenorSurvey />,
	},
	{
		path: "/reduce-min-pay",
		element: <ReduceMinPaySurvey />,
	},
]);
