import React, { ReactNode } from "react";
import { Button } from "@mui/material";
import { ButtonProps } from "@mui/material";

export default function CustomButton({
  height,
  children,
  ...props
}: CustomButtonProps) {
  return (
    <Button
      variant="contained"
      size="large"
      fullWidth
      type={"submit"}
      sx={{
        height: height ? height : "48px",
        borderRadius: "12px",
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

interface CustomButtonProps extends ButtonProps {
  children: ReactNode;
  height?: string | number;
}
