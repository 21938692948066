import { Box, Typography } from "@mui/material";
import _ from "lodash";
import { useContext, useState } from "react";
import { postSubmitReduceMinPaySurvey } from "../apis/survey";
import SurveyForm from "../components/SurveyForm";
import { LoadingContext } from "../contexts/LoadingContext";
import { Answer } from "../types/survey.type";
import { SurveyMapper } from "../mapper/survey.mapper";

const survey = {
	title: () => (
		<>
			บอกสั้นๆ เกี่ยวกับ <br /> สถานการณ์ทางการเงินของคุณ
		</>
	),
	questions: [
		{
			id: 1,
			type: "checkbox",
			title: "1. สาเหตุใดทำให้คุณไม่สามารถจ่าย ค่างวดได้ตามกำหนด (เลือกได้มากกว่า 1 ข้อ)",
			required: true,
			options: [
				{
					id: 1,
					label: "ค่าใช้จ่ายเพิ่มขึ้น",
					value: "ค่าใช้จ่ายเพิ่มขึ้น",
				},
				{
					id: 2,
					label: "รายรับลดลง",
					value: "รายรับลดลง",
				},
				{
					id: 3,
					label: "ตกงาน",
					value: "ตกงาน",
				},
				{
					id: 4,
					label: "อื่นๆ",
					value: "อื่นๆ",
					inputOption: {
						enable: true,
						placeholder: "อื่นๆ โปรดระบุ",
					},
				},
			],
		},
		{
			id: 2,
			type: "number",
			title: "2. ค่างวดเดือนละเท่าไหร่ที่คุณจ่ายไหวในช่วงนี้",
			required: true,
			helperText:
				"ข้อมูลนี้เป็นเพียงข้อมูลที่ช่วยให้บริษัทเข้าใจคุณเท่านั้น",
		},
		{
			id: 3,
			type: "textarea",
			title: "3. คุณคาดว่าจะเอาเงินจากที่ใดมาจ่ายค่างวด",
			inputMaxLength: 100,
		},
	],
};

export default function ReduceMinPaySurvey() {
	const [answers, setAnswers] = useState<Map<number, Answer>>(new Map());
	const { setLoading, setIsSnackbarOpen } = useContext(LoadingContext);

	const onSubmitSurvey = async () => {
		setLoading(true);
		const sortedAnswers = _.sortBy(
			Array.from(answers.values()),
			"question"
		);
		const response = await postSubmitReduceMinPaySurvey(
			SurveyMapper.toDomain(sortedAnswers)
		);
		if (response?.status === 200) {
			setLoading(false);
		} else {
			setLoading(false);
			setIsSnackbarOpen(true);
		}
	};

	return (
		<>
			<Box
				className="App"
				sx={{
					paddingTop: 2,
					paddingBottom: 10,
				}}
			>
				<Typography variant="thunderHead">{survey.title()}</Typography>
				<SurveyForm
					questions={survey.questions}
					answers={answers}
					setAnswers={setAnswers}
					onSubmit={onSubmitSurvey}
				/>
			</Box>
		</>
	);
}
