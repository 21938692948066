import React, { useState } from "react";
import { Alert, Container, Snackbar } from "@mui/material";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme";
import { LoadingContext } from "./contexts/LoadingContext";
import { LoadingBackDrop } from "./components";
import { router } from "./utils/router";
import { RouterProvider } from "react-router-dom";

function App() {
	const [loading, setLoading] = useState<boolean>(false);
	const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

	const handleCloseSnackbar = (
		event?: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}

		setIsSnackbarOpen(false);
	};

	return (
		<LoadingContext.Provider
			value={{ loading, setLoading, isSnackbarOpen, setIsSnackbarOpen }}
		>
			<ThemeProvider theme={theme}>
				<LoadingBackDrop />
				<Snackbar
					open={isSnackbarOpen}
					autoHideDuration={6000}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					onClose={handleCloseSnackbar}
				>
					<Alert severity="error">Error!</Alert>
				</Snackbar>
				<Container maxWidth="md">
					<RouterProvider router={router} />
				</Container>
			</ThemeProvider>
		</LoadingContext.Provider>
	);
}

export default App;
