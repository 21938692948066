import { FormHelperText, SxProps } from "@mui/material";
import { ReactNode } from "react";

export default function CustomFormHelperText({
	children,
	sx,
	error,
	...props
}: CustomFormHelperTextProps) {
	return (
		<FormHelperText
			error={error}
			sx={{ marginX: 0, fontSize: 12, fontWeight: 300, ...sx }}
			{...props}
		>
			{children}
		</FormHelperText>
	);
}

type CustomFormHelperTextProps = {
	children: ReactNode;
	sx?: SxProps;
	error?: boolean;
};
