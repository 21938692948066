import { Box, FormControlLabel, Typography } from "@mui/material";
import { Answer, Question, QuestionOption } from "../types/survey.type";
import CustomCheckbox from "./CustomCheckbox";
import CustomTextField from "./CustomTextField";

export default function CheckboxList(props: CheckboxListProps) {
	const {
		question,
		options,
		answers,
		handleAnswerChange,
		handleCheckboxInputChange,
	} = props;

	return (
		<>
			{options?.map((option) => {
				return (
					<Box key={option.value}>
						<FormControlLabel
							control={
								<CustomCheckbox
									onChange={(e) => {
										handleAnswerChange(e, question);
									}}
									name={option.value}
								/>
							}
							label={
								<Typography variant="thunderBody">
									{option.label}
								</Typography>
							}
						/>
						<Box>
							{option.inputOption?.enable && (
								<CustomTextField
									placeholder={option.inputOption.placeholder}
									value={
										answers.get(question.id)?.otherAnswer ??
										""
									}
									onChange={(
										e: React.ChangeEvent<HTMLInputElement>
									) => {
										handleCheckboxInputChange(e, question);
									}}
									disabled={
										!answers
											.get(question.id)
											?.answer.includes("อื่นๆ")
									}
									inputProps={{
										"data-testid": `otherInputOption${option.id}`,
									}}
								/>
							)}
						</Box>
					</Box>
				);
			})}
		</>
	);
}

type CheckboxListProps = {
	question: Question;
	options: QuestionOption[];
	answers: Map<number, Answer>;
	handleAnswerChange: (
		e: React.ChangeEvent<HTMLInputElement>,
		question: Question
	) => void;
	handleCheckboxInputChange: (
		e: React.ChangeEvent<HTMLInputElement>,
		question: Question
	) => void;
};
