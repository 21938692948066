import { createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { ThemeOptions } from "@mui/material/styles";
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    thunderHead: true;
    thunderSubtitle: true;
    thunderBody: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  thunderHead: React.CSSProperties;
  thunderSubtitle: React.CSSProperties;
  thunderBody: React.CSSProperties;
}

export const theme = createTheme({
  typography: {
    fontFamily: `Kanit, sans-serif`,
    fontSize: 14,
    thunderHead: {
      fontWeight: 500,
      fontSize: 24,
    },
    thunderSubtitle: {
      fontSize: 14,
      fontWeight: 500,
    },
    thunderBody: {
      fontSize: 14,
      fontWeight: 300,
    },
  } as ExtendedTypographyOptions,
  palette: {
    primary: {
      main: "#2D2D9F",
    },
    action: {
      disabledBackground: "#C8C8D0",
      disabled: "#FFFFFF",
    },
  },
} as ThemeOptions);
