import axios from "axios";
import { baseURL } from "../config";

const client = axios.create({
  baseURL,
  headers: {
    post: {
      "Content-Type": "application/json",
    },
  },
});

export { client };
