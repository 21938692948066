import { Answer } from "../types/survey.type";

export class SurveyMapper {
	static toDomain(answers: Answer[]) {
		return answers.map((data) => {
			const { question, questionLabel, answer, otherAnswer } = data;
			return {
				question,
				questionLabel,
				answers: Array.isArray(answer)
					? [...answer, otherAnswer].filter(Boolean)
					: [answer],
			};
		});
	}
}
