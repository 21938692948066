import {
	Box,
	Container,
	FormControl,
	FormGroup,
	Typography,
} from "@mui/material";
import CustomTextField from "./CustomTextField";
import CustomFormHelperText from "./CustomFormHelperText";
import CustomButton from "./CustomButton";
import CheckboxList from "./CheckboxList";
import { Answer, Question } from "../types/survey.type";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export default function QuestionForm(props: SurveyQuestionProps) {
	const { questions, answers, setAnswers, onSubmit } = props;
	const [disableSubmitButton, setDisableSubmitButton] =
		useState<boolean>(true);

	const handleAnswerChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		question: Question
	) => {
		const { id } = question;
		const {
			name: eventTargetName,
			value: eventTargetValue,
			checked: eventTargetChecked,
		} = e.target;
		const clonedAnswers = new Map(answers);
		if (question.type === "checkbox") {
			const data =
				clonedAnswers.get(id) ??
				({
					question: id,
					questionLabel: question.title,
					answer: [],
					otherAnswer: "",
				} as Answer);

			const answer = eventTargetChecked
				? [...data.answer, eventTargetName]
				: data.answer.filter(
						(item: string) => item !== eventTargetName
				  );
			clonedAnswers.set(id, {
				...data,
				answer,
				otherAnswer: answer.includes("อื่นๆ")
					? data.otherAnswer
					: "",
			});
		}

		if (
			question.type === "number" ||
			question.type === "text" ||
			question.type === "textarea"
		) {
			clonedAnswers.set(id, {
				question: id,
				questionLabel: question.title,
				answer: eventTargetValue,
			});
		}

		setAnswers(clonedAnswers);
	};

	const handleCheckboxInputChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		question: Question
	) => {
		const { id } = question;
		const { value: eventTargetValue } = e.target;
		const clonedAnswers = new Map(answers);
		const data = clonedAnswers.get(id) ?? ({} as Answer);
		clonedAnswers.set(id, {
			...data,
			otherAnswer: eventTargetValue,
		});
		setAnswers(clonedAnswers);
	};

	useEffect(() => {
		const requiredQuestions = questions.filter(
			(question) => question?.required === true
		);

		const isInputAllRequiredFields = requiredQuestions.every((question) => {
			const answer = answers.get(question.id) ?? ({} as Answer);
			if (answer?.answer !== null && answer?.answer?.length > 0) {
				return true;
			}
			return false;
		});

		setDisableSubmitButton(!isInputAllRequiredFields);
	}, [answers, questions]);

	return (
		<FormControl>
			{questions.map((question) => {
				return (
					<FormGroup key={question.title} sx={{ marginTop: 2 }}>
						<Typography
							variant="thunderSubtitle"
							data-testid={`question${question.id}Title`}
						>
							{question.title}
						</Typography>
						{(() => {
							if (question.type === "checkbox") {
								return (
									<CheckboxList
										question={question}
										options={question?.options ?? []}
										answers={answers}
										handleAnswerChange={handleAnswerChange}
										handleCheckboxInputChange={
											handleCheckboxInputChange
										}
									/>
								);
							}

							if (question.type === "textarea") {
								return (
									<>
										<CustomTextField
											onChange={(
												e: React.ChangeEvent<HTMLInputElement>
											) => {
												handleAnswerChange(e, question);
											}}
											multiline
											rows={4}
											inputProps={{
												maxLength:
													question.inputMaxLength,
												"data-testid": `textareaQuestion${question.id}`,
											}}
										/>
										<CustomFormHelperText
											sx={{
												display: "flex",
												position: "relative",
												top: -25,
												justifyContent: "flex-end",
												right: 10,
											}}
											data-testid={`helperTextQuestion${question.id}`}
										>
											{answers.get(question.id)?.answer
												.length ?? 0}
											/{question.inputMaxLength}
										</CustomFormHelperText>
									</>
								);
							}

							return (
								<>
									<CustomTextField
										error={true}
										type={question.type}
										onChange={(
											e: React.ChangeEvent<HTMLInputElement>
										) => {
											handleAnswerChange(e, question);
										}}
										inputProps={{
											"data-testid": `textfieldQuestion${question.id}`,
										}}
									/>
									{question.helperText && (
										<CustomFormHelperText>
											{question.helperText}
										</CustomFormHelperText>
									)}
								</>
							);
						})()}
					</FormGroup>
				);
			})}
			<Box
				sx={{
					position: "fixed",
					bottom: 0,
					left: 0,
					right: 0,
					display: "flex",
					height: 72,
					marginTop: -100,
					width: "100%",
					backgroundColor: "#FFFFFF",
					justifyContent: "center",
					alignItems: "center",
					boxShadow: "0px 0px 24px rgba(222, 222, 227, 0.48)",
				}}
			>
				<Container maxWidth="md">
					<CustomButton
						type="submit"
						disabled={disableSubmitButton}
						onClick={onSubmit}
						data-testid="submitButton"
					>
						ไปต่อ
					</CustomButton>
				</Container>
			</Box>
		</FormControl>
	);
}

type SurveyQuestionProps = {
	questions: Question[];
	answers: Map<number, Answer>;
	setAnswers: Dispatch<SetStateAction<Map<number, Answer>>>;
	onSubmit: () => void;
};
