import { createContext, Dispatch, SetStateAction } from "react";

type LoadingContextType = {
	loading: boolean;
	setLoading: Dispatch<SetStateAction<boolean>>;
	isSnackbarOpen: boolean;
	setIsSnackbarOpen: Dispatch<SetStateAction<boolean>>;
};

export const LoadingContext = createContext<LoadingContextType>({
	loading: false,
	setLoading: () => {},
	isSnackbarOpen: false,
	setIsSnackbarOpen: () => {},
});
