import React, { useEffect, useState, useRef, useContext } from "react";
import {
	Typography,
	Box,
	FormControl,
	FormGroup,
	FormControlLabel,
	Container,
} from "@mui/material";
import {
	CustomButton,
	CustomFormHelperText,
	CustomTextField,
	CustomCheckbox,
} from "../components";
import { postSubmitExtendTenorSurvey } from "../apis/survey";
import { LoadingContext } from "../contexts/LoadingContext";
const InputNumberThreeMaxLength: number = 100;

const question1 = {
	questionTitle: "1. สาเหตุใดทำให้คุณไม่สามารถจ่ายค่างวดได้ตามกำหนด",
	answers: [
		{
			answer: 1,
			answerLabel: "ค่าใช้จ่ายเพิ่มขึ้น",
			answerValue: "ค่าใช้จ่ายเพิ่มขึ้น",
		},
		{
			answer: 2,
			answerLabel: "รายรับลดลง",
			answerValue: "รายรับลดลง",
		},
		{
			answer: 3,
			answerLabel: "ตกงาน",
			answerValue: "ตกงาน",
		},
		{
			answer: 4,
			answerLabel: "อื่นๆ",
			answerValue: "อื่นๆ",
		},
	],
};

const question2 = {
	questionTitle: "2. คุณจ่ายค่างวดได้เดือนละกี่บาท",
};

const question3 = {
	questionTitle: "3. คุณคาดว่าจะเอาเงินจากที่ใดมาจ่ายค่างวด",
};

export default function ExtendTenorSurvey() {
	const [answer1, setAnswer1] = useState<string[]>([]);
	const [answer1Option, setAnswer1Option] = useState<string>("");
	const [answer2, setAnswer2] = useState<string>("");
	const [answer3, setAnswer3] = useState<string>("");
	const [isAnswer1Error, setIsAnswer1Error] = useState<boolean>(false);
	const [isAnswer2Error, setIsAnswer2Error] = useState<boolean>(false);
	const firstRender = useRef(true);
	const { setLoading, setIsSnackbarOpen } = useContext(LoadingContext);

	const onAnswer1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			setAnswer1([...answer1, e.target.name]);
		} else {
			if (answer1.includes("อื่นๆ")) {
				setAnswer1Option("");
			}
			let answer1Array = answer1;
			answer1Array = answer1Array.filter(
				(value) => value !== e.target.name
			);
			setAnswer1(answer1Array);
		}
	};

	const onAnswer1OptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAnswer1Option(e.target.value);
	};

	const onAnswer2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAnswer2(e.target.value);
	};

	const onAnswer3Change = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAnswer3(e.target.value);
	};

	const submitSurvey = async () => {
		setLoading(true);
		const answer1Array: string[] = answer1;
		answer1Option && answer1Array.push(answer1Option);
		const answer3Array: string[] = [];
		answer3 && answer3Array.push(answer3);
		const response = await postSubmitExtendTenorSurvey([
			{
				question: 1,
				questionLabel: question1.questionTitle,
				answers: answer1Array,
			},
			{
				question: 2,
				questionLabel: question2.questionTitle,
				answers: [answer2],
			},
			{
				question: 3,
				questionLabel: question3.questionTitle,
				answers: answer3Array,
			},
		]);
		if (response?.status === 200) {
			setLoading(false);
		} else {
			setLoading(false);
			setIsSnackbarOpen(true);
		}
	};

	const handleClickGoOn = () => {
		submitSurvey();
	};

	useEffect(() => {
		if (
			answer1.includes(question1.answers[3].answerValue) &&
			!answer1Option
		) {
			setIsAnswer1Error(true);
		} else {
			setIsAnswer1Error(false);
		}
	}, [answer1, answer1Option]);

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
			return;
		}
		!answer2 ? setIsAnswer2Error(true) : setIsAnswer2Error(false);
	}, [answer2]);

	return (
		<>
			<Box
				className="App"
				sx={{
					paddingTop: 2,
					paddingBottom: 10,
				}}
			>
				<Typography marginBottom="24px" variant="thunderHead">
					บอกสั้นๆ เกี่ยวกับ <br /> สถานการณ์ทางการเงินของคุณ
				</Typography>
				<FormControl>
					<Typography variant="thunderSubtitle">
						{question1.questionTitle}
					</Typography>
					<FormGroup>
						{question1.answers.map((answer, index) => {
							return (
								<FormControlLabel
									key={index}
									control={
										<CustomCheckbox
											onChange={onAnswer1Change}
											name={answer.answerValue}
										/>
									}
									label={
										<Typography variant="thunderBody">
											{answer.answerLabel}
										</Typography>
									}
								/>
							);
						})}

						<CustomTextField
							error={isAnswer1Error}
							placeholder="อื่นๆ โปรดระบุ"
							value={answer1Option}
							onChange={onAnswer1OptionChange}
							disabled={!answer1.includes("อื่นๆ")}
							inputProps={{
								"data-testid": "inputOptionAnswer1",
							}}
						/>
					</FormGroup>
					<FormGroup sx={{ marginTop: 2 }}>
						<Typography variant="thunderSubtitle">
							{question2.questionTitle}
						</Typography>
						<CustomTextField
							error={isAnswer2Error}
							type="number"
							value={answer2}
							onChange={onAnswer2Change}
							inputProps={{ "data-testid": "inputAnswer2" }}
						/>
						<CustomFormHelperText>
							ค่างวดนี้เป็นเพียงข้อมูลที่ช่วยให้บริษัทเข้าใจคุณ
							โดยคุณจะต้องเลือกค่างวดที่ต้องการจ่ายอีกครั้ง
						</CustomFormHelperText>
					</FormGroup>
					<FormGroup sx={{ marginTop: 2 }}>
						<Typography variant="thunderSubtitle">
							{question3.questionTitle}
						</Typography>
						<CustomTextField
							value={answer3}
							onChange={onAnswer3Change}
							multiline
							rows={4}
							inputProps={{
								maxLength: InputNumberThreeMaxLength,
								"data-testid": "inputAnswer3",
							}}
						/>
						<CustomFormHelperText
							sx={{
								display: "flex",
								position: "relative",
								top: -25,
								justifyContent: "flex-end",
								right: 10,
							}}
						>
							{answer3.length}/{InputNumberThreeMaxLength}
						</CustomFormHelperText>
					</FormGroup>
					<Box
						sx={{
							position: "fixed",
							bottom: 0,
							left: 0,
							right: 0,
							display: "flex",
							height: 72,
							marginTop: -100,
							width: "100%",
							backgroundColor: "#FFFFFF",
							justifyContent: "center",
							alignItems: "center",
							boxShadow: "0px 0px 24px rgba(222, 222, 227, 0.48)",
						}}
					>
						<Container maxWidth="md">
							<CustomButton
								type="submit"
								disabled={
									!answer1.length ||
									!answer2 ||
									isAnswer1Error ||
									isAnswer2Error
								}
								onClick={handleClickGoOn}
							>
								ไปต่อ
							</CustomButton>
						</Container>
					</Box>
				</FormControl>
			</Box>
		</>
	);
}
