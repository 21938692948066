import { styled } from "@mui/material/styles";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";

const Icon = styled("span")(({ theme }) => ({
  borderRadius: "6px",
  border: "2px solid #DEDEE3",
  width: 24,
  height: 24,
  boxShadow: "0px 2px 4px rgba(18, 18, 64, 0.08)",
  backgroundColor: "#ffffff",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #2D2D9F",
    outlineOffset: 2,
  },

  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const CheckedIcon = styled(CheckIcon)({
  width: 24,
  height: 24,
  border: "2px solid #2D2D9F",
  borderRadius: "6px",
  boxShadow: "0px 2px 4px rgba(18, 18, 64, 0.08)",
});

export default function CustomCheckbox({ ...props }: CustomCheckboxProps) {
  return <Checkbox icon={<Icon />} checkedIcon={<CheckedIcon />} {...props} />;
}

interface CustomCheckboxProps extends CheckboxProps {}
