import { Backdrop, CircularProgress, Typography } from "@mui/material";
import React, { useContext } from "react";
import { LoadingContext } from "../contexts/LoadingContext";

export default function LoadingBackDrop() {
  const { loading } = useContext(LoadingContext);
  return (
    <Backdrop
      open={loading}
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        flexDirection: "column",
      }}
    >
      <CircularProgress color="inherit" />
      <Typography mt={1} variant="thunderBody">
        Loading...
      </Typography>
    </Backdrop>
  );
}
